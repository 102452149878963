var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

/*! modernizr 3.6.0 (Custom Build) | MIT *
 * https://modernizr.com/download/?-objectfit-addtest-atrule-domprefixes-hasevent-mq-prefixed-prefixedcss-prefixedcssvalue-prefixes-setclasses-testallprops-testprop-teststyles !*/
!function (e, t, n) {
  function r(e, t) {
    return (typeof e === "undefined" ? "undefined" : _typeof(e)) === t;
  }function o() {
    var e, t, n, o, i, s, u;for (var a in S) {
      if (S.hasOwnProperty(a)) {
        if (e = [], t = S[a], t.name && (e.push(t.name.toLowerCase()), t.options && t.options.aliases && t.options.aliases.length)) for (n = 0; n < t.options.aliases.length; n++) {
          e.push(t.options.aliases[n].toLowerCase());
        }for (o = r(t.fn, "function") ? t.fn() : t.fn, i = 0; i < e.length; i++) {
          s = e[i], u = s.split("."), 1 === u.length ? Modernizr[u[0]] = o : (!Modernizr[u[0]] || Modernizr[u[0]] instanceof Boolean || (Modernizr[u[0]] = new Boolean(Modernizr[u[0]])), Modernizr[u[0]][u[1]] = o), _.push((o ? "" : "no-") + u.join("-"));
        }
      }
    }
  }function i(e) {
    return e.replace(/([a-z])-([a-z])/g, function (e, t, n) {
      return t + n.toUpperCase();
    }).replace(/^-/, "");
  }function s(e) {
    return e.replace(/([A-Z])/g, function (e, t) {
      return "-" + t.toLowerCase();
    }).replace(/^ms-/, "-ms-");
  }function u(e) {
    var t = x.className,
        n = Modernizr._config.classPrefix || "";if (P && (t = t.baseVal), Modernizr._config.enableJSClass) {
      var r = new RegExp("(^|\\s)" + n + "no-js(\\s|$)");t = t.replace(r, "$1" + n + "js$2");
    }Modernizr._config.enableClasses && (t += " " + n + e.join(" " + n), P ? x.className.baseVal = t : x.className = t);
  }function a() {
    return "function" != typeof t.createElement ? t.createElement(arguments[0]) : P ? t.createElementNS.call(t, "http://www.w3.org/2000/svg", arguments[0]) : t.createElement.apply(t, arguments);
  }function l(e, t) {
    if ("object" == (typeof e === "undefined" ? "undefined" : _typeof(e))) for (var n in e) {
      T(e, n) && l(n, e[n]);
    } else {
      e = e.toLowerCase();var r = e.split("."),
          o = Modernizr[r[0]];if (2 == r.length && (o = o[r[1]]), "undefined" != typeof o) return Modernizr;t = "function" == typeof t ? t() : t, 1 == r.length ? Modernizr[r[0]] = t : (!Modernizr[r[0]] || Modernizr[r[0]] instanceof Boolean || (Modernizr[r[0]] = new Boolean(Modernizr[r[0]])), Modernizr[r[0]][r[1]] = t), u([(t && 0 != t ? "" : "no-") + r.join("-")]), Modernizr._trigger(e, t);
    }return Modernizr;
  }function f(e, t) {
    return !!~("" + e).indexOf(t);
  }function c() {
    var e = t.body;return e || (e = a(P ? "svg" : "body"), e.fake = !0), e;
  }function p(e, n, r, o) {
    var i,
        s,
        u,
        l,
        f = "modernizr",
        p = a("div"),
        d = c();if (parseInt(r, 10)) for (; r--;) {
      u = a("div"), u.id = o ? o[r] : f + (r + 1), p.appendChild(u);
    }return i = a("style"), i.type = "text/css", i.id = "s" + f, (d.fake ? d : p).appendChild(i), d.appendChild(p), i.styleSheet ? i.styleSheet.cssText = e : i.appendChild(t.createTextNode(e)), p.id = f, d.fake && (d.style.background = "", d.style.overflow = "hidden", l = x.style.overflow, x.style.overflow = "hidden", x.appendChild(d)), s = n(p, e), d.fake ? (d.parentNode.removeChild(d), x.style.overflow = l, x.offsetHeight) : p.parentNode.removeChild(p), !!s;
  }function d(e, t) {
    return function () {
      return e.apply(t, arguments);
    };
  }function v(e, t, n) {
    var o;for (var i in e) {
      if (e[i] in t) return n === !1 ? e[i] : (o = t[e[i]], r(o, "function") ? d(o, n || t) : o);
    }return !1;
  }function m(t, n, r) {
    var o;if ("getComputedStyle" in e) {
      o = getComputedStyle.call(e, t, n);var i = e.console;if (null !== o) r && (o = o.getPropertyValue(r));else if (i) {
        var s = i.error ? "error" : "log";i[s].call(i, "getComputedStyle returning null, its possible modernizr test results are inaccurate");
      }
    } else o = !n && t.currentStyle && t.currentStyle[r];return o;
  }function h(t, r) {
    var o = t.length;if ("CSS" in e && "supports" in e.CSS) {
      for (; o--;) {
        if (e.CSS.supports(s(t[o]), r)) return !0;
      }return !1;
    }if ("CSSSupportsRule" in e) {
      for (var i = []; o--;) {
        i.push("(" + s(t[o]) + ":" + r + ")");
      }return i = i.join(" or "), p("@supports (" + i + ") { #modernizr { position: absolute; } }", function (e) {
        return "absolute" == m(e, null, "position");
      });
    }return n;
  }function y(e, t, o, s) {
    function u() {
      c && (delete q.style, delete q.modElem);
    }if (s = r(s, "undefined") ? !1 : s, !r(o, "undefined")) {
      var l = h(e, o);if (!r(l, "undefined")) return l;
    }for (var c, p, d, v, m, y = ["modernizr", "tspan", "samp"]; !q.style && y.length;) {
      c = !0, q.modElem = a(y.shift()), q.style = q.modElem.style;
    }for (d = e.length, p = 0; d > p; p++) {
      if (v = e[p], m = q.style[v], f(v, "-") && (v = i(v)), q.style[v] !== n) {
        if (s || r(o, "undefined")) return u(), "pfx" == t ? v : !0;try {
          q.style[v] = o;
        } catch (g) {}if (q.style[v] != m) return u(), "pfx" == t ? v : !0;
      }
    }return u(), !1;
  }function g(e, t, n, o, i) {
    var s = e.charAt(0).toUpperCase() + e.slice(1),
        u = (e + " " + A.join(s + " ") + s).split(" ");return r(t, "string") || r(t, "undefined") ? y(u, t, o, i) : (u = (e + " " + j.join(s + " ") + s).split(" "), v(u, t, n));
  }function C(e, t, r) {
    return g(e, n, n, t, r);
  }var _ = [],
      S = [],
      w = { _version: "3.6.0", _config: { classPrefix: "", enableClasses: !0, enableJSClass: !0, usePrefixes: !0 }, _q: [], on: function on(e, t) {
      var n = this;setTimeout(function () {
        t(n[e]);
      }, 0);
    }, addTest: function addTest(e, t, n) {
      S.push({ name: e, fn: t, options: n });
    }, addAsyncTest: function addAsyncTest(e) {
      S.push({ name: null, fn: e });
    } },
      Modernizr = function Modernizr() {};Modernizr.prototype = w, Modernizr = new Modernizr();var b = w._config.usePrefixes ? " -webkit- -moz- -o- -ms- ".split(" ") : ["", ""];w._prefixes = b;var x = t.documentElement,
      P = "svg" === x.nodeName.toLowerCase(),
      E = "Moz O ms Webkit",
      j = w._config.usePrefixes ? E.toLowerCase().split(" ") : [];w._domPrefixes = j;var z = function z(e, t) {
    var n = !1,
        r = a("div"),
        o = r.style;if (e in o) {
      var i = j.length;for (o[e] = t, n = o[e]; i-- && !n;) {
        o[e] = "-" + j[i] + "-" + t, n = o[e];
      }
    }return "" === n && (n = !1), n;
  };w.prefixedCSSValue = z;var T;!function () {
    var e = {}.hasOwnProperty;T = r(e, "undefined") || r(e.call, "undefined") ? function (e, t) {
      return t in e && r(e.constructor.prototype[t], "undefined");
    } : function (t, n) {
      return e.call(t, n);
    };
  }(), w._l = {}, w.on = function (e, t) {
    this._l[e] || (this._l[e] = []), this._l[e].push(t), Modernizr.hasOwnProperty(e) && setTimeout(function () {
      Modernizr._trigger(e, Modernizr[e]);
    }, 0);
  }, w._trigger = function (e, t) {
    if (this._l[e]) {
      var n = this._l[e];setTimeout(function () {
        var e, r;for (e = 0; e < n.length; e++) {
          (r = n[e])(t);
        }
      }, 0), delete this._l[e];
    }
  }, Modernizr._q.push(function () {
    w.addTest = l;
  });var A = w._config.usePrefixes ? E.split(" ") : [];w._cssomPrefixes = A;var N = function N(t) {
    var r,
        o = b.length,
        i = e.CSSRule;if ("undefined" == typeof i) return n;if (!t) return !1;if (t = t.replace(/^@/, ""), r = t.replace(/-/g, "_").toUpperCase() + "_RULE", r in i) return "@" + t;for (var s = 0; o > s; s++) {
      var u = b[s],
          a = u.toUpperCase() + "_" + r;if (a in i) return "@-" + u.toLowerCase() + "-" + t;
    }return !1;
  };w.atRule = N;var L = function () {
    function e(e, t) {
      var o;return e ? (t && "string" != typeof t || (t = a(t || "div")), e = "on" + e, o = e in t, !o && r && (t.setAttribute || (t = a("div")), t.setAttribute(e, ""), o = "function" == typeof t[e], t[e] !== n && (t[e] = n), t.removeAttribute(e)), o) : !1;
    }var r = !("onblur" in t.documentElement);return e;
  }();w.hasEvent = L;var O = function () {
    var t = e.matchMedia || e.msMatchMedia;return t ? function (e) {
      var n = t(e);return n && n.matches || !1;
    } : function (t) {
      var n = !1;return p("@media " + t + " { #modernizr { position: absolute; } }", function (t) {
        n = "absolute" == (e.getComputedStyle ? e.getComputedStyle(t, null) : t.currentStyle).position;
      }), n;
    };
  }();w.mq = O;var k = (w.testStyles = p, { elem: a("modernizr") });Modernizr._q.push(function () {
    delete k.elem;
  });var q = { style: k.elem.style };Modernizr._q.unshift(function () {
    delete q.style;
  });w.testProp = function (e, t, r) {
    return y([e], n, t, r);
  };w.testAllProps = g;var R = w.prefixed = function (e, t, n) {
    return 0 === e.indexOf("@") ? N(e) : (-1 != e.indexOf("-") && (e = i(e)), t ? g(e, t, n) : g(e, "pfx"));
  };w.prefixedCSS = function (e) {
    var t = R(e);return t && s(t);
  };Modernizr.addTest("objectfit", !!R("objectFit"), { aliases: ["object-fit"] }), w.testAllProps = C, o(), u(_), delete w.addTest, delete w.addAsyncTest;for (var M = 0; M < Modernizr._q.length; M++) {
    Modernizr._q[M]();
  }e.Modernizr = Modernizr;
}(window, document);